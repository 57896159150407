export const validateEmail = (value: string | undefined): string | undefined => {
  if (value && /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,}$/.test(value)) {
    return
  }
  return 'invalid_email'
}

export const validatePassword = (value: string | undefined, minLength = 6): string | undefined => {
  return (
    validateLength(value, minLength) ||
    validatePatternMatch(value, /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/) ||
    undefined
  )
}

export const validatePatternMatch = (
  value: string | undefined,
  pattern: RegExp,
): string | undefined => {
  if (value && pattern.test(value)) {
    return
  }
  return 'pattern_mismatch'
}

export const validateWebsite = (value: string | undefined) => {
  return validatePatternMatch(
    value,
    /^((https?):\/\/)?([a-zA-Z0-9-_]+\.[a-zA-Z0-9-_]+)*(\/[a-zA-Z0-9-_.]+)*\/?(\?[a-zA-Z0-9-_.]*=[a-zA-Z0-9-_'" .]*)*(&[a-zA-Z0-9-_.]*=[a-zA-Z0-9-_'" .]*)*$/,
  )
}

export const validateValueMatch = (
  left: string | undefined,
  right: string | undefined,
): string | undefined => {
  if (left && right && left === right) {
    return
  }
  return 'mismatch'
}

export const validateLength = (value: string | undefined, min: number): string | undefined => {
  if (value && value.length >= min) {
    return
  }
  return 'insufficient_length'
}

export const validateLineOfEffort = (arr: { details: string }[]): string | undefined => {
  if (arr.some((el) => el.details && el.details.trim() !== '')) {
    return
  }
  return 'need at least one valid line of effort'
}

export const validateYear = (
  value: string | number | undefined,
  lowerBounds = 1800,
  upperBounds: number = new Date().getFullYear(),
): string | undefined => {
  if (!value) {
    return 'invalid_year'
  }

  const normalized = typeof value === 'string' ? Number(value) : value
  if (isNaN(normalized)) {
    return 'not_a_number'
  }
  if (normalized >= lowerBounds && normalized <= upperBounds) {
    return
  }
  return 'year_out_of_bounds'
}
