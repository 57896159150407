import { ApplicationStore, NotificationStore, ServerStore } from '../../../stores'
import { inject, observer } from 'mobx-react'
import { PaginatedSearchableParams } from '../../../lib/types/Params'
import { CCol, CFormLabel, CRow, CButton } from '@coreui/react'
import {
  isDirtySelectableItem,
  RichTextEditor,
  SelectableItem,
  ServerBackedMultiSelectAutoComplete,
  SuspensefulButton,
  BasicInput,
} from '@mobilizeyourtech/vision-core-react'
import React from 'react'
import { GovUserExtendedDataFormData, LineOfEffortFormData } from './types'
import { TechnologyTypeResponse } from '../../../stores/ServerStore'

export interface GovUserExtendedDataProfileFormProps<T extends GovUserExtendedDataFormData> {
  value: Partial<T>
  onChange: (value: Partial<T>) => void
  serverStore?: ServerStore
  notificationStore?: NotificationStore
}

export const GovUserExtendedDataProfileForm = inject(
  ApplicationStore.names.serverStore,
  ApplicationStore.names.notificationStore,
)(
  observer(
    <T extends GovUserExtendedDataFormData>(props: GovUserExtendedDataProfileFormProps<T>) => {
      const onChange = (change: Partial<GovUserExtendedDataFormData>) =>
        props.onChange({ ...props.value, ...change })

      // TECH TYPES DROPDOWN START //////
      const getTechnologyTypes = (params: PaginatedSearchableParams) => {
        return props.serverStore!.getTechnologyTypes(params).then((response) => ({
          pagination: response.pagination,
          data: response.data.map((r) => {
            return { ...r, label: r.name }
          }),
        }))
      }
      const getSelectedTechnologyTypes = () => {
        if (props.value.technologyTypes === undefined) {
          return []
        }

        return props.value.technologyTypes?.map((t) => {
          return { ...t, label: t.name }
        })
      }

      const renderLineOfEffortInputs = () => {
        const lineOfEffortArray = (props.value.linesOfEffort as LineOfEffortFormData[]) || []

        return lineOfEffortArray.map((loe, idx) => {
          return !loe.destroy ? (
            <div key={idx} className="d-flex flex-column w-100">
              {idx !== 0 && (
                <div className="d-flex justify-content-end mb-1">
                  <CButton
                    onClick={() => removeLineOfEffort(idx)}
                    className="delete-line-of-effort-btn d-flex justify-content-center align-items-center"
                    color="dark"
                    data-testid="remove-loe-button"
                  >
                    <i className="fas fa-times" />
                  </CButton>
                </div>
              )}
              <BasicInput
                maxCharacters={300}
                value={loe.details}
                onChange={(e) => updateLineOfEffort(e.target.value, idx)}
                data-testid="loe-input"
              />
            </div>
          ) : null
        })
      }

      const addLineOfEffort = () => {
        const newLineOfEffort = { details: '' }
        onChange({
          linesOfEffort: [
            ...((props.value.linesOfEffort as LineOfEffortFormData[]) || []),
            newLineOfEffort,
          ],
        })
      }

      const removeLineOfEffort = (idx: number) => {
        // TODO - this is temporary. To be refactored when implementing EDIT
        const updatedLineOfEffort = [...props.value.linesOfEffort!]

        const item = updatedLineOfEffort[idx]
        if (item?.id) {
          updatedLineOfEffort[idx] = { ...item, destroy: true }
        } else {
          updatedLineOfEffort.splice(idx, 1)
        }

        onChange({ linesOfEffort: updatedLineOfEffort })
      }

      const updateLineOfEffort = (value: string, idx: number) => {
        const updatedLineOfEffort = props.value.linesOfEffort!.map((loe, i) =>
          i === idx ? { ...loe, details: value } : loe,
        )

        onChange({
          linesOfEffort: updatedLineOfEffort,
        })
      }

      // TECH TYPES DROPDOWN END ///////

      const technologyTypesRow = () => {
        return (
          <CRow className="mb-3 w-50">
            <CFormLabel className="w-100 col-sm-2 col-form-label">
              Technology Types <i className="fas fa-asterisk gov-required-icon" />
              (required)
            </CFormLabel>
            <CCol>
              <ServerBackedMultiSelectAutoComplete
                required
                loadMore={getTechnologyTypes}
                onChange={(selected) => {
                  onChange({
                    technologyTypes: selected.filter((e) => !isDirtySelectableItem(e)) as Array<
                      TechnologyTypeResponse & SelectableItem
                    >,
                  })
                }}
                selections={getSelectedTechnologyTypes()}
                placeholder="Select Technology Types..."
                className="technology-type-search-dropdown ValidatedInput"
                data-testid="technology-type-search-dropdown"
              />
            </CCol>
          </CRow>
        )
      }

      const linesOfEffortRow = () => {
        return (
          <CRow className="mb-3">
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9'}>
              <CFormLabel className="w-100 col-sm-2 col-form-label">
                Lines of Effort <i className="fas fa-asterisk gov-required-icon" />
                (required)
              </CFormLabel>
              <span className={'subtle'}>
                What areas or problems are you looking for new emerging technologies to solve for
                your mission?
              </span>
              <CCol className="mt-2">
                {renderLineOfEffortInputs()}
                <div className="d-flex justify-content-end align-items-end py-2">
                  {props.value.linesOfEffort &&
                    props.value.linesOfEffort.filter((loe) => !loe.destroy).length < 6 && (
                      <SuspensefulButton
                        className="d-inline justify-self-end align-self-end"
                        isSuspended={false}
                        onInvoke={addLineOfEffort}
                        buttonText={'Add Line of Effort'}
                        data-testid="add-loe-button"
                      />
                    )}
                </div>
              </CCol>
            </CCol>
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-lg-5 exampleDiv'}>
              <div>Example:</div>
              <p>
                Air Force Special Warfare unit is looking for portable electronic warfare systems
                with excellent SWaP characteristics for field use. They must be able to cover
                2-10GHz.
              </p>
            </CCol>
          </CRow>
        )
      }

      const profileDetailsRow = () => {
        return (
          <CRow className="mb-3">
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9'}>
              <CFormLabel className="w-100 col-sm-2 col-form-label">
                About you <i className="fas fa-asterisk gov-required-icon" />
                (required)
              </CFormLabel>
              <span className={'subtle'}>
                Role description, problems you’re solving, and anything else you want people to
                know.
              </span>
              <CCol>
                <RichTextEditor
                  className="profile-details-editor"
                  data-testid="profile-details-editor"
                  value={props.value.profileDetail}
                  onChange={(value) => {
                    onChange({ profileDetail: value })
                  }}
                />
              </CCol>
            </CCol>
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-lg-5 exampleDiv'}>
              <div>Example:</div>
              <p>
                Hi! I am a program manager working specifically in advanced manufacturing. I’m
                especially interested in innovative 3D printing opportunities.
              </p>
              <br />
              <p>
                Currently, we’re trying to source materials for a 3D printing project that is
                focused on Tactical Combat Casualty Care (TCCC).
              </p>
            </CCol>
          </CRow>
        )
      }

      const interactionDetailRow = () => {
        return (
          <CRow className="mb-3">
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9'}>
              <CRow>
                <CFormLabel className="w-100 col-sm-2 col-form-label">
                  How can you work with small businesses?{' '}
                  <i className="fas fa-asterisk gov-required-icon" />
                  (required)
                </CFormLabel>
                <CCol>
                  <RichTextEditor
                    className="interaction-details-editor"
                    data-testid="interaction-details-editor"
                    value={props.value.interactionDetail || ''}
                    onChange={(value) => {
                      onChange({ interactionDetail: value })
                    }}
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol className={'col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 mt-lg-4 exampleDiv'}>
              <div>Example:</div>
              <p>TPOC, MOU, Phase 1 stakeholder engagement, etc.</p>
            </CCol>
          </CRow>
        )
      }

      return (
        <div className={'GovUserExtendedDataProfileForm'}>
          {technologyTypesRow()}
          {linesOfEffortRow()}
          {profileDetailsRow()}
          {interactionDetailRow()}
        </div>
      )
    },
  ),
)
